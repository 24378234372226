import {
  Box,
  List,
  CheckSquare,
  Edit2,
  FileText,
  Sliders,
  Download,
  Upload,
  Settings,
} from "lucide-react";
import IntegrationEditorLeftPanel, {
  IntegrationEditorLeftPanelVerticalTab,
} from "../../shared/integration-editor-base/IntegrationEditorLeftPanel";
import BlueprintEditorLeftPanelOverviewSubtab from "./BlueprintEditorLeftPanelOverviewSubtab";
import BlueprintEditorLeftPanelConsoleSubtab from "./BlueprintEditorLeftPanelConsoleSubtab";
import BlueprintEditorLeftPanelSchemasSubtab from "./BlueprintEditorLeftPanelSchemasSubtab";
import useBlueprintContext from "../context/useBlueprintContext";
import styled from "styled-components";
import {
  BLUEPRINT_EDITOR_README_TAB_PATH,
  getBlueprintEditorPath,
  getBlueprintEditorValidationTabPath,
  getBlueprintEditorReturnSchemaTabPath,
  getBlueprintReadmeTabPath,
} from "../../../router/RouterUtils";
import { useEffect, useReducer, useState } from "react";
import { fetchWithAuth } from "../../../api-client/api_client";
import { CommonModelInfo, LinkedAccount } from "../../../models/Entities";
import {
  initializeInputParams,
  initializeInputParamsForProxy,
  isBlueprintWriteOperation,
} from "../utils/BlueprintEditorUtils";
import {
  AsyncTaskExecution,
  AsyncTaskExecutionPreview,
  BlueprintOperationType,
  BlueprintTestPayload,
  BlueprintVersionPublishState,
  JSONObjectSchema,
} from "../../../models/Blueprints";
import { matchPath, useLocation } from "react-router-dom";
import { showErrorToast } from "../../shared/Toasts";
import { getReturnSchemaForScraper } from "../../scraper/utils/ScraperUtils";
import BlueprintEditorLeftPanelConnectorSubtab from "./BlueprintEditorLeftPanelConnectorSubtab";
import {
  AbstractConditionAction,
  AbstractConditionReducerState,
  abstractConditionsForSelectiveSyncReducer,
} from "../reducers/AbstractConditionsForSelectiveSyncReducer";

const LeftPanelContainer = styled.div`
  min-width: 22%;
  max-width: 22%;
  background-color: white;
  border-right: 1px solid rgb(220, 228, 240);
`;

const OPERATION_TYPES_WITH_FIELD_VALIDATION = [
  BlueprintOperationType.CREATE,
  BlueprintOperationType.UPSERT,
  BlueprintOperationType.EDIT,
  BlueprintOperationType.META,
];

const QUICKBOOKS_DESKTOP_INTEGRATION_ID = "4f340c8e-10d1-4989-8c36-579c6e0f0b3c";

export function updateMaxLoopIterationPerStep(
  maxLoopIterationPerStep: { [stepID: string]: number },
  stepID: string,
  value: number | undefined,
  setMaxLoopIterationPerStep: (x: { [stepID: string]: number }) => void
): void {
  if (value && value !== 0) {
    setMaxLoopIterationPerStep({ ...maxLoopIterationPerStep, [stepID]: value });
  } else {
    const updatedMaxLoopIterationPerStep = Object.fromEntries(
      Object.entries(maxLoopIterationPerStep).filter(([key]) => key !== stepID)
    );
    setMaxLoopIterationPerStep(updatedMaxLoopIterationPerStep);
  }
}

const BlueprintEditorLeftPanel = () => {
  const { blueprint, doesBlueprintHaveUnsavedChanges } = useBlueprintContext();
  const {
    parameter_schema,
    human_name,
    name,
    operation_type,
    integration,
    version,
    qbxml_query_request_format,
  } = blueprint;
  const [selectedTestLinkedAccount, setSelectedTestLinkedAccount] = useState<undefined | string>();
  const [selectedTestCommonModel, setSelectedTestCommonModel] = useState<undefined | string>();
  const [isTestingBlueprint, setIsTestingBlueprint] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowingModelsModal, setIsShowingModelsModal] = useState<boolean>(true);
  const [testLinkedAccounts, setTestLinkedAccounts] = useState<LinkedAccount[]>([]);
  const [isLoadingTestLinkedAccounts, setIsLoadingTestLinkedAccounts] = useState<boolean>(false);
  const [testCommonModels, setTestCommonModels] = useState<undefined | CommonModelInfo[]>();
  const [disableFilterByDate, setDisableFilterByDate] = useState<boolean>(false);
  const [disableSyncCursor, setDisableSyncCursor] = useState<boolean>(false);
  const [shouldUseLastScraperResults, setShouldUseLastScraperResults] = useState<boolean>(
    blueprint.scraper !== undefined
  );
  const [shouldFilterDisabledFields, setShouldFilterDisabledFields] = useState<boolean>(false);
  const [shouldRunAsAsyncTask, setShouldRunAsAsyncTask] = useState<boolean>(false);
  const [shouldGenerateMappingTest, setShouldGenerateMappingTest] = useState<boolean>(false);
  const [asyncTaskExecutionID, setAsyncTaskExecutionID] = useState<string | undefined>();
  const [asyncTaskExecutionResult, setAsyncTaskExecutionResult] = useState<
    AsyncTaskExecution | undefined
  >();
  const [maxLoopIterations, setMaxLoopIterations] = useState<number>();
  const [maxLoopIterationsPerStep, setMaxLoopIterationsPerStep] = useState<{
    [stepID: string]: number;
  }>({});
  const [maxPageIterations, setMaxPageIterations] = useState<number>();
  const [frozenTimeValue, setFrozenTimeValue] = useState<string>();
  const [overrideLastRunAtValue, setOverrideLastRunAtValue] = useState<string>();
  const [shouldIncludeNestedParams, setShouldIncludeNestedParams] = useState<boolean>(false);
  const [globalVarsAsString, setGlobalVarsAsString] = useState<string>();
  const [initializedSchemaForDeletePayload, setInitializedSchemaForDeletePayload] = useState<
    string
  >("");
  const [recentTestRuns, setRecentTestRuns] = useState<AsyncTaskExecutionPreview[]>([]);
  const [isLoadingRecentTestRuns, setIsLoadingRecentTestRuns] = useState<boolean>(false);
  const [isShowingRecentTestRunsModal, setIsShowingRecentTestRunsModal] = useState<boolean>(false);
  const [selectedTestPayload, setSelectedTestPayload] = useState<
    undefined | BlueprintTestPayload
  >();
  const [blueprintTestPayloads, setBlueprintTestPayloads] = useState<BlueprintTestPayload[]>([]);
  const [generatedBlueprintTestPayloads, setGeneratedBlueprintTestPayloads] = useState<
    BlueprintTestPayload[]
  >([]);
  // Reducer for managing state of AbstractConditions for Selective Sync filter in Blueprint Editor
  const [
    abstractConditionsForSelectiveSync,
    dispatchAbstractConditionsForSelectiveSync,
  ] = useReducer<
    (
      state: AbstractConditionReducerState,
      action: AbstractConditionAction
    ) => AbstractConditionReducerState
  >(abstractConditionsForSelectiveSyncReducer, []);

  const updatedParameterSchema = blueprint.updated_parameter_schema_for_auto_update;

  const hasInputParameters =
    Object.keys(blueprint.parameter_schema?.properties ?? {}).length > 0 ||
    blueprint.scraper ||
    updatedParameterSchema;

  function fetchSavedPayloads() {
    if (!hasInputParameters) return;
    fetchWithAuth({
      path: `/blueprints/${blueprint.id}/test-payloads`,
      method: "GET",
      onResponse: (response) => {
        setBlueprintTestPayloads(response);
      },
      onError: () => {
        showErrorToast("Failed to fetch test payloads.");
      },
    });
  }

  useEffect(fetchSavedPayloads, []);

  useEffect(() => {
    setIsLoadingTestLinkedAccounts(true);
    fetchWithAuth({
      path: `/integrations/linked-accounts?is_test_account=True&integration=${integration.id}`,
      method: "GET",
      onResponse: (data: { results: LinkedAccount[] }) => {
        setIsLoadingTestLinkedAccounts(false);
        setTestLinkedAccounts(data.results);
        setSelectedTestLinkedAccount(data.results[0].id);
      },
      onError: () => setIsLoadingTestLinkedAccounts(false),
    });
  }, [integration.id]);

  useEffect(() => {
    if (!selectedTestLinkedAccount) return;
    fetchWithAuth({
      path: `/blueprints/${blueprint.id}/${selectedTestLinkedAccount}/test-model-ids`,
      method: "GET",
      onResponse: (results: { models?: CommonModelInfo[] }) => {
        if (!results.models) return;
        setTestCommonModels(results.models);
        if (results.models.length > 0) setSelectedTestCommonModel(results.models[0].id);
      },
      onError: () => {
        showErrorToast("Failed to fetch test common models.");
      },
    });
  }, [selectedTestLinkedAccount]);

  useEffect(() => {
    const hasInputParameters =
      blueprint.scraper ?? Object.keys(parameter_schema?.properties ?? {}).length > 0;
    if (blueprint.scraper) {
      const scraperParameterSchema: JSONObjectSchema = {
        type: "object",
        properties: {
          scraper_return_values: getReturnSchemaForScraper(blueprint.scraper),
        },
      };
      const initializedParams = initializeInputParams(
        scraperParameterSchema,
        shouldIncludeNestedParams
      );
      setInitializedSchemaForDeletePayload(JSON.stringify(initializedParams, null, 2));
      if (!selectedTestPayload) {
        setGlobalVarsAsString(JSON.stringify(initializedParams, null, 2));
      }
    } else if (parameter_schema && hasInputParameters) {
      const initializedParams = initializeInputParams(parameter_schema, shouldIncludeNestedParams);
      setInitializedSchemaForDeletePayload(JSON.stringify(initializedParams, null, 2));
      if (!selectedTestPayload) {
        setGlobalVarsAsString(JSON.stringify(initializedParams, null, 2));
      }
    }
  }, [parameter_schema, shouldIncludeNestedParams, blueprint?.scraper?.version_id]);

  useEffect(() => {
    if (blueprint.operation_type == BlueprintOperationType.PROXY) {
      const remoteId = testCommonModels?.find((i) => i.id === selectedTestCommonModel)?.remote_id;
      if (remoteId) {
        const initializedParams = initializeInputParamsForProxy(remoteId);
        setGlobalVarsAsString(JSON.stringify(initializedParams, null, 2));
        setInitializedSchemaForDeletePayload(JSON.stringify(initializedParams, null, 2));
      }
    }
  }, [selectedTestCommonModel]);

  const location = useLocation();
  const readmePathMatch = matchPath(location.pathname, BLUEPRINT_EDITOR_README_TAB_PATH);

  const isSelectedLinkedAccountASandboxAccount =
    testLinkedAccounts.find((linkedAccount) => linkedAccount.id === selectedTestLinkedAccount)
      ?.is_sandbox_account ?? false;

  const canAutogenerateMappingTestForBlueprint =
    [BlueprintVersionPublishState.Staged, BlueprintVersionPublishState.Published].includes(
      blueprint.version.publish_state
    ) &&
    !doesBlueprintHaveUnsavedChanges &&
    isSelectedLinkedAccountASandboxAccount &&
    isBlueprintWriteOperation(blueprint.operation_type);

  const integrationID = integration.id;
  const tabs = readmePathMatch
    ? null
    : [
        {
          key: "overview",
          navbarText: "Overview",
          Icon: Box,
          content: <BlueprintEditorLeftPanelOverviewSubtab />,
        },
        {
          key: "console",
          navbarText: "Console",
          Icon: Sliders,
          content: (
            <BlueprintEditorLeftPanelConsoleSubtab
              hasInputParameters={hasInputParameters}
              updatedParameterSchema={updatedParameterSchema}
              blueprintTestPayloads={blueprintTestPayloads}
              setBlueprintTestPayloads={setBlueprintTestPayloads}
              generatedBlueprintTestPayloads={generatedBlueprintTestPayloads}
              setGeneratedBlueprintTestPayloads={setGeneratedBlueprintTestPayloads}
              selectedTestPayload={selectedTestPayload}
              setSelectedTestPayload={setSelectedTestPayload}
              selectedTestLinkedAccount={selectedTestLinkedAccount}
              setSelectedTestLinkedAccount={setSelectedTestLinkedAccount}
              selectedTestCommonModel={selectedTestCommonModel}
              setSelectedTestCommonModel={setSelectedTestCommonModel}
              isTestingBlueprint={isTestingBlueprint}
              setIsTestingBlueprint={setIsTestingBlueprint}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              isLoadingTestLinkedAccounts={isLoadingTestLinkedAccounts}
              testLinkedAccounts={testLinkedAccounts}
              testCommonModels={testCommonModels}
              disableFilterByDate={disableFilterByDate}
              setDisableFilterByDate={setDisableFilterByDate}
              disableSyncCursor={disableSyncCursor}
              canAutogenerateMappingTestForBlueprint={canAutogenerateMappingTestForBlueprint}
              setDisableSyncCursor={setDisableSyncCursor}
              shouldUseLastScraperResults={shouldUseLastScraperResults}
              setShouldUseLastScraperResults={setShouldUseLastScraperResults}
              maxLoopIterations={maxLoopIterations}
              setMaxLoopIterations={setMaxLoopIterations}
              maxLoopIterationsPerStep={maxLoopIterationsPerStep}
              setMaxLoopIterationsPerStep={setMaxLoopIterationsPerStep}
              maxPageIterations={maxPageIterations}
              setMaxPageIterations={setMaxPageIterations}
              frozenTimeValue={frozenTimeValue}
              setFrozenTimeValue={setFrozenTimeValue}
              overrideLastRunAtValue={overrideLastRunAtValue}
              setOverrideLastRunAtValue={setOverrideLastRunAtValue}
              shouldIncludeNestedParams={shouldIncludeNestedParams}
              setShouldIncludeNestedParams={setShouldIncludeNestedParams}
              globalVarsAsString={globalVarsAsString}
              setGlobalVarsAsString={setGlobalVarsAsString}
              initializedSchemaForDeletePayload={initializedSchemaForDeletePayload}
              isShowingModelsModal={isShowingModelsModal}
              setIsShowingModelsModal={setIsShowingModelsModal}
              shouldFilterDisabledFields={shouldFilterDisabledFields}
              setShouldFilterDisabledFields={setShouldFilterDisabledFields}
              shouldRunAsAsyncTask={shouldRunAsAsyncTask}
              setShouldRunAsAsyncTask={setShouldRunAsAsyncTask}
              shouldGenerateMappingTest={shouldGenerateMappingTest}
              setShouldGenerateMappingTest={setShouldGenerateMappingTest}
              asyncTaskExecutionID={asyncTaskExecutionID}
              setAsyncTaskExecutionID={setAsyncTaskExecutionID}
              asyncTaskExecutionResult={asyncTaskExecutionResult}
              setAsyncTaskExecutionResult={setAsyncTaskExecutionResult}
              recentTestRuns={recentTestRuns}
              setRecentTestRuns={setRecentTestRuns}
              isLoadingRecentTestRuns={isLoadingRecentTestRuns}
              setIsLoadingRecentTestRuns={setIsLoadingRecentTestRuns}
              isShowingRecentTestRunsModal={isShowingRecentTestRunsModal}
              setIsShowingRecentTestRunsModal={setIsShowingRecentTestRunsModal}
              doesBlueprintHaveUnsavedChanges={doesBlueprintHaveUnsavedChanges}
              abstractConditionsForSelectiveSync={abstractConditionsForSelectiveSync}
              dispatchAbstractConditionsForSelectiveSync={
                dispatchAbstractConditionsForSelectiveSync
              }
            />
          ),
        },
        {
          key: "schema",
          navbarText: "Schemas",
          Icon: List,
          content: <BlueprintEditorLeftPanelSchemasSubtab />,
        },
        ...(integrationID === QUICKBOOKS_DESKTOP_INTEGRATION_ID
          ? [
              {
                key: "web-connector",
                navbarText: "Connector",
                Icon: Settings,
                content: (
                  <BlueprintEditorLeftPanelConnectorSubtab
                    qbXMLQueryRequestFormat={qbxml_query_request_format}
                    parameterSchema={parameter_schema}
                    operationType={blueprint.operation_type}
                  />
                ),
              },
            ]
          : []),
      ];

  const versionID = version.id;

  const verticalTabs: IntegrationEditorLeftPanelVerticalTab[] = [
    {
      title: "Blueprint Editor",
      href: getBlueprintEditorPath(integrationID, versionID),
      Icon: Edit2,
    },
    {
      title: "Readme",
      href: getBlueprintReadmeTabPath(integrationID, versionID),
      Icon: FileText,
    },
    {
      title: "Field Validation",
      href: getBlueprintEditorValidationTabPath(integrationID, versionID),
      Icon: CheckSquare,
    },
    {
      title: "Parameters",
      href: getBlueprintEditorValidationTabPath(integrationID, versionID),
      Icon: Download,
    },
    {
      title: "Return Values",
      href: getBlueprintEditorReturnSchemaTabPath(integrationID, versionID),
      Icon: Upload,
    },
  ].filter(
    (field) =>
      (field.title !== "Field Validation" ||
        OPERATION_TYPES_WITH_FIELD_VALIDATION.includes(operation_type)) &&
      (!["Parameters", "Return Values"].includes(field.title) ||
        operation_type === BlueprintOperationType.FUNCTIONAL)
  );

  return (
    <LeftPanelContainer>
      <IntegrationEditorLeftPanel
        header={human_name || name}
        hasUnsavedChanges={doesBlueprintHaveUnsavedChanges}
        subheader={integration.name}
        integration={integration}
        tabs={tabs}
        verticalTabs={verticalTabs}
      />
    </LeftPanelContainer>
  );
};

export default BlueprintEditorLeftPanel;
